import {Component, OnInit} from '@angular/core';
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {IModule} from "../../../core/models/module.model";
import {InstitutionService} from "../../../core/services/institution.service";
import {IInstitution} from "../../../core/models/institution.model";
import {ToastService} from "../../../core/services/toast.service";
import {getUnpagedPageable} from "../../../core/models/page.model";
import {ModuleService} from "../../../core/services/module.service";
import {CheckboxModule} from "primeng/checkbox";
import {NgForOf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";

@Component({
  selector: 'app-module-management',
  standalone: true,
  imports: [
    CheckboxModule,
    NgForOf,
    FormsModule,
    Button
  ],
  templateUrl: './module-management.component.html',
  styleUrl: './module-management.component.scss'
})
export class ModuleManagementComponent implements OnInit {

  institution: IInstitution | undefined;
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  modules: IModule[] = [];
  selectedModules: IModule[] = [];

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly moduleService: ModuleService,
    private readonly dialogService: DialogService,
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.loadModules();
    this.boundData = this.instance?.data;
    this.institution = this.boundData?.data as IInstitution;
    this.selectedModules = this.institution?.modules || [];
  }

  loadModules() {
    this.moduleService.getAllModules(getUnpagedPageable()).subscribe({
      next: (page) => this.modules = page.content,
      error: (err) => {
        this.toastService.showToast('Récupération des modules', err.error, 'error');
        this.ref.close();
      }
    });
  }

  setModules() {
    if (!this.institution) return this.alertInstitutionNotSelected();
    this.institutionService.setModules(this.institution?.id, this.selectedModules).subscribe({
      next: () => {
        this.toastService.showToast('Gestion des modules', 'Les modules pour l\'institution mis à jour avec succès');
        this.ref?.close();
      },
      error: (err) => this.toastService.showToast('Gestion des modules', err.error, 'error')
    });
  }

  alertInstitutionNotSelected() {
    this.toastService.showToast('Gestion des modules', 'Veuillez sélectionner une institution', 'error');
    return;
  }


}
