
import { UploadOperationComponent } from "./upload-operation/upload-operation.component";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {IOperation} from "../../core/models/operation.model";
import {take} from "rxjs";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";
import {OperationService} from "../../core/services/operation.service";
import {ToastService} from "../../core/services/toast.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {StorageService} from "../../core/services/storage.service";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-operation',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './operation.component.html',
  styleUrl: './operation.component.scss'
})
export class OperationComponent implements OnInit, OnDestroy {

  operations: IOperation[] = [];
  columns: CustomTableHeader[] = [
    { key: 'state', column: 'Status', type: 'text' },
    { key: 'operationDate', column: 'Date opération', type: 'date' },
    { key: 'valueDate', column: 'Date valeur', type: 'date' },
    { key: 'libelle', column: 'Libelle', type: 'text' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'devise.code' },
    { key: 'account.libelle', column: 'Compte', type: 'text' },
    { key: 'sens', column: 'Sens', type: 'text' }
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];
  toolbarActions: CustomAction[] = [];

  constructor(
    private operationService: OperationService,
    private toastService: ToastService,
    public dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.loadOperations();
    this.actions = this.getActions();
    this.toolbarActions = this.getToolbarActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getToolbarActions(): CustomAction[] {
    return [
      {
        role: 'CREATE_OPERATION',
        icon: 'pi pi-plus',
        label: 'Créer',
        severity: 'primary',
        emit: () => this.createOperation(),
        visible: true
      },
      {
        role: 'CREATE_OPERATION',
        icon: 'pi pi-download',
        label: 'Charger',
        severity: 'primary',
        emit: () => this.uploadOperation(),
        visible: true
      },
      {
        role: 'READ_OPERATION',
        icon: 'pi pi-upload',
        label: 'Exporter',
        severity: 'info',
        emit: () => this.loadOperations(),
        visible: true
      }
    ];
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_OPERATION',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewOperation(item),
        visible: true
      },
      {
        role: 'UPDATE_OPERATION',
        icon: 'pi pi-pen-to-square',
        label: 'Edit',
        severity: 'primary',
        emit: (item) => this.editOperation(item),
        visible: true
      },
      {
        role: 'DELETE_OPERATION',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteOperation(item),
        visible: true
      },
    ];
  }

  loadOperations(pageable: Pageable = getDefaultPageable()) {
    this.operationService.getOperations(pageable).subscribe({
      next: page => {
        this.operations = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        };
      },
      error: err => this.toastService.showToast('Chargement des opérations', err.error, 'error')
    });
  }

  viewOperation(operation: IOperation) {
    const data = {isView: true, data: operation} as CustomDialogData;
    this.ref = this.dialogService.open(UploadOperationComponent, customDynamicDialog('Détails opération', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperations());
  }

  editOperation(operation: IOperation) {
    const data = {isEdit: true, data: operation} as CustomDialogData;
    this.ref = this.dialogService.open(UploadOperationComponent, customDynamicDialog('Modification opération', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperations());
  }

  createOperation() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(UploadOperationComponent, customDynamicDialog('Création opération', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperations());
  }

  deleteOperation(operation: IOperation) {
    const summary = 'Opération supprimée';
    this.operationService.deleteOperation(operation.id!).subscribe({
      next: () => {
        this.toastService.showToast(summary, `L'opération ${operation.libelle} a été supprimée avec succès.`);
        this.loadOperations();
      },
      error: err => this.toastService.showToast(summary, err.error, 'error')
    });
  }

  uploadOperation() {
    const options = { position: 'center', width: '75vw', height: '75vh' };
    this.ref = this.dialogService.open(UploadOperationComponent, customDynamicDialog('Chargement d\'opérations', {}, options));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperations());
  }

  onPageChange = (event: PaginatorState) => this.loadOperations(getDefaultPageable(event.page, event.rows));
}
