<p-toolbar>
  <div class="p-toolbar-group-start">
    <div class="font-bold text-2xl text-color-secondary"> {{ title }} </div>
  </div>
  <div *ngIf="search.observed" class="p-toolbar-group-center">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText placeholder="Recherche" />
        </span>
  </div>
  <div class="p-toolbar-group-end" *ngIf="this.actions.length > 0">
    <div *ngFor="let action of actions">
      <p-button [severity]="action.severity" [icon]="action.icon" (onClick)="this.action.emit($event)" class="mr-2"/>
    </div>
  </div>
</p-toolbar>
