import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from "../models/page.model";
import {IOperation, IOperationExcel} from "../models/operation.model";

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private apiUrl = `operation/v1/operations`;

  constructor(private http: HttpClient) { }

  createOperation(operation: IOperation): Observable<IOperation> {
    return this.http.post<IOperation>(this.apiUrl, operation);
  }

  uploadOperations(file: File): Observable<IOperationExcel[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<IOperationExcel[]>(`${this.apiUrl}/upload`, formData);
  }

  createOperations(operations: IOperation[]): Observable<IOperation[]> {
    return this.http.post<IOperation[]>(`${this.apiUrl}/batch`, operations);
  }

  getOperationById(id: string): Observable<IOperation> {
    return this.http.get<IOperation>(`${this.apiUrl}/${id}`);
  }

  getOperations(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(this.apiUrl, { params });
  }

  getOperationsByAccountId(accountId: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/account/${accountId}`, { params });
  }

  getLocalOperationsForInstitution(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/local`, { params });
  }

  getForeignOperationsForInstitution(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/foreign`, { params });
  }

  getFutureOperationsForInstitution(institutionId: string, isLocal: boolean, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('institutionId', institutionId);
    params = params.append('isLocal', isLocal.toString());
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/future`, { params });
  }

  getFutureOperationsForAccount(accountId: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('id', accountId);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/future/account`, { params });
  }

  getOperationsByStateAndInstitutionId(institutionId: string, state: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/state/${state}`, { params });
  }

  getOperationsByDateRangeAndInstitutionId(institutionId: string, startDate: Date, endDate: Date, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/dateRange`, { params });
  }

  getOperationsByStartDate(institutionId: string, startDate: Date, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('startDate', startDate.toISOString());
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/startDate`, { params });
  }

  updateOperation(id: string, operation: IOperation): Observable<IOperation> {
    return this.http.put<IOperation>(`${this.apiUrl}/${id}`, operation);
  }

  deleteOperation(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
