<p-table [value]="data" [columns]="headers" styleClass="p-datatable-sm" [tableStyle]="{'min-width': '50rem'}">
  <ng-template pTemplate="header">
    <tr class="header font-bold">
      <th scope="col" *ngFor="let header of headers">{{ header.column }}</th>
      <th scope="col" class="text-center" *ngIf="actions.length > 0">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td *ngFor="let header of headers" class="truncate" [pTooltip]="getNestedValue(item, header.key)">
        <ng-container [ngSwitch]="header.type">
          <ng-container *ngSwitchCase="'text'">{{ getNestedValue(item, header.key) }}</ng-container>
          <ng-container *ngSwitchCase="'currency'">
            {{ getNestedValue(item, header.key) | currency : (header.currencyKey ? getNestedValue(item, header.currencyKey) : 'XOF') : 'symbol' : '1.2-2' : 'fr-FR' }}
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            {{ getNestedValue(item, header.key) | number:'1.2-2' }}
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            {{ getNestedValue(item, header.key) | date:'dd/MM/yyyy' }}
          </ng-container>
          <span *ngSwitchCase="'text'">{{ getNestedValue(data, header.key) }}</span>
          <span *ngSwitchCase="'currency'">{{ getNestedValue(data, header.key) | currency : 'XOF' }}</span>
          <span *ngSwitchCase="'date'">{{ getNestedValue(data, header.key) | date : 'dd/MM/yyyy, hh:mm' }}</span>
          <span *ngSwitchCase="'state'">
            <span *ngIf="getNestedValue(data, header.key) == 'PENDING'">
              <p-tag [style]="{ background: 'var(--teal-200)' }">
                <span class="pi pi-stopwatch mr-1" [style]="{ color: 'var(--teal-800)' }"></span>
                <span [style]="{ color: 'var(--teal-800)' }">En attente</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'ACTIVE'">
              <p-tag [style]="{ background: 'var(--green-200)' }">
                <span class="pi pi-check-circle mr-1" [style]="{ color: 'var(--green-500)' }"></span>
                <span [style]="{ color: 'var(--green-500)' }">Active</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'INACTIVE'">
              <p-tag [style]="{ background: 'var(--red-200)' }">
                <span class="pi pi-times-circle mr-1" [style]="{ color: 'var(--red-500)' }"></span>
                <span [style]="{ color: 'var(--red-500)' }">Inactive</span>
              </p-tag>
            </span>
          </span>
          <ng-container *ngSwitchCase="'boolean'">
            <i class="pi" [ngClass]="{'pi-check-circle text-green-500': getNestedValue(item, header.key), 'pi-times-circle text-red-500': !getNestedValue(item, header.key)}"></i>
          </ng-container>
          <span *ngSwitchDefault>{{ getNestedValue(data, header.key) }}</span>

          <ng-container *ngSwitchCase="'state'">
            <ng-container [ngSwitch]="getNestedValue(item, header.key)">
              <span *ngSwitchCase="'PENDING'" class="text-teal-800">
                  <i class="pi pi-stopwatch mr-1"></i>
              </span>
              <span *ngSwitchCase="'ACTIVE'" class="text-green-500">
                  <i class="pi pi-check-circle mr-1"></i>
              </span>
              <span *ngSwitchCase="'INACTIVE'" class="text-red-500">
                  <i class="pi pi-times-circle mr-1"></i>
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ getNestedValue(item, header.key) }}</ng-container>
        </ng-container>
      </td>
      <td *ngIf="this.actions.length > 0">
        <div class="card flex justify-content-center">
          <p-button [rounded]="true" size="small" [text]="true" [outlined]="true" severity="secondary"
                    (onClick)="emit(item, $event, menu);" icon="pi pi-ellipsis-v"
                    [disabled]="getActionsForRow(data).length === 0" />
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-menu #menu [popup]="true" />
<div class="flex-auto">
  <p-paginator (onPageChange)="paginate.emit($event)"  [showCurrentPageReport]="true" currentPageReportTemplate="{last} / {totalRecords}"
               [first]="pageParams.first" [rows]="pageParams.rows" [totalRecords]="pageParams.totalRecords"
               [rowsPerPageOptions]="pageParams.rowPerPageOptions" />
</div>
