import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { getDefaultPageable, getPageableAsParams, Page, Pageable } from '../models/page.model';
import { IBank } from "../models/bank.model";

@Injectable({
  providedIn: 'root',
})
export class BankService {
  private baseUrl = 'operation/v1/bank';

  constructor(private http: HttpClient) {}

  createBank(bankDto: IBank, logo: File): Observable<IBank> {
    const formData: FormData = new FormData();
    formData.append('logo', logo, logo.name);
    formData.append('bank', JSON.stringify(bankDto));
    return this.http.post<IBank>(this.baseUrl, formData).pipe(take(1));
  }

  updateBank(id: string, bankDto: IBank): Observable<IBank> {
    return this.http.put<IBank>(`${this.baseUrl}/${id}`, bankDto).pipe(take(1));
  }

  deleteBank(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getBankById(id: string): Observable<IBank> {
    return this.http.get<IBank>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getAllBanks(pageable: Pageable = getDefaultPageable()): Observable<Page<IBank>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IBank>>(this.baseUrl, { params }).pipe(take(1));
  }

  searchBanksByName(query: string, pageable: Pageable = getDefaultPageable()): Observable<Page<IBank>> {
    let params = getPageableAsParams(pageable).append('query', query);
    return this.http.get<Page<IBank>>(`${this.baseUrl}/search`, { params }).pipe(take(1));
  }
}
