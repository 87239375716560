import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginatorModule, PaginatorState } from "primeng/paginator";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import {
  CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgForOf,
  NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault
} from "@angular/common";
import {MenuItem, SharedModule} from "primeng/api";
import { TableModule } from "primeng/table";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../core/utils/utils";
import { PageParams } from "../../../core/models/page.model";
import { TagModule } from "primeng/tag";
import { CustomAction, CustomTableHeader } from "../../../core/models/custom-table.model";
import { HasRoleDirective } from "../../../core/directives/has-role.directive";
import { StorageService } from "../../../core/services/storage.service";
import { SpeedDialModule } from "primeng/speeddial";
import { SplitButtonModule } from "primeng/splitbutton";
import { Menu, MenuModule } from "primeng/menu";

@Component({
  selector: 'dynamic-table',
  standalone: true,
  imports: [
    // Modules
    ButtonModule, CheckboxModule, PaginatorModule, SharedModule, TableModule, TagModule, SpeedDialModule,
    SplitButtonModule, MenuModule,
    // Pipes & Directives
    CurrencyPipe, DatePipe, NgIf, NgForOf, NgSwitch, NgSwitchCase, NgSwitchDefault, HasRoleDirective,
    NgClass, DecimalPipe
  ],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnInit {

  @Input({required: true}) headers : CustomTableHeader[] = [];
  @Input({required: true}) data : any[] = [];
  @Input({required: true}) pageParams: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS};
  @Input() actions: CustomAction[] = [];
  @Output() paginate = new EventEmitter<PaginatorState>();
  items: MenuItem[] = [];
  selectedData: any;

  constructor(private tokenManager: StorageService) {}

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  ngOnInit() {
    if (this.actions.length > 0) {
      const jwt = this.tokenManager.getToken();
      this.actions = this.actions.filter(action =>
        jwt.authorities.some(role => action.role === role) &&
        (typeof action.visible === 'boolean' ? action.visible : true)
      );
    }
  }

  getActionsForRow(rowData: any): MenuItem[] {
    return this.actions
      .filter(action =>
        typeof action.visible === 'function' ? action.visible(rowData) : action.visible
      )
      .map(action => ({
        label: action.label,
        icon: action.icon,
        command: () => action.emit(this.selectedData)
      }));
  }

  emit(data: any, $event: MouseEvent, menu: Menu) {
    console.log('Data: ', data);
    this.selectedData = data;
    menu.model = this.getActionsForRow(data);
    menu.toggle($event);
  }
}
