import {HttpParams} from "@angular/common/http";

/**
 * Object models for a page of data
 */
export interface Pageable {
  page: number;
  limit: number;
  sort: string;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  direction: Direction;
  orders: Order[];
}

export interface Order {
  property: string;
  ignoreCase: boolean;
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  first: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  empty: boolean;
}

export const DEFAULT_SORT: Sort = {
  direction: Direction.DESC,
  orders: [
    {
      property: 'createdDate',
      ignoreCase: false,
    },
  ],
}

export function getDefaultPageable(page: number = 0, size: number = 0, sort: Sort = DEFAULT_SORT): Pageable {
  return {
    page: page,
    limit: size,
    sort: sort.orders.map(order => `${order.property},${sort.direction}`).join(','),
    offset: page * size,
    paged: true,
    unpaged: false,
  } as Pageable;
}

export function getUnpagedPageable(sort: Sort = DEFAULT_SORT): Pageable {
  return {
    page: 0,
    paged: false,
    unpaged: true,
    sort: sort.orders.map(order => `${order.property},${sort.direction}`).join(','),
  } as Pageable;
}


export function getPageableAsParams(pageable: Pageable): HttpParams {
  let params = new HttpParams()
    .set('page', pageable.page?.toString())
    .set('size', pageable.limit?.toString())  // Changed 'limit' to 'size'
    .set('sort', pageable.sort);

  if (pageable.paged !== undefined) params = params.set('isPaged', pageable.paged.toString());

  return params;
}

export interface PageParams {
  page: number;
  first: number;
  rows: number;
  totalRecords: number;
  rowPerPageOptions: number[];
}
