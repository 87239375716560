import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {NgForOf, NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputTextareaModule} from "primeng/inputtextarea";
import {BankService} from "../../../core/services/bank.service";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {ToastService} from "../../../core/services/toast.service";
import {IBank} from "../../../core/models/bank.model";
import {AvatarModule} from "primeng/avatar";

@Component({
  selector: 'app-bank-details',
  standalone: true,
  imports: [
    // Modules
    FileUploadModule, ReactiveFormsModule, InputTextModule, ButtonModule,
    // Directives & Pipes
    NgForOf, NgIf, InputTextareaModule, AvatarModule
  ],
  templateUrl: './bank-details.component.html',
  styleUrl: './bank-details.component.scss'
})
export class BankDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    swiftCode: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    codeBank: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    telephone: new FormControl(''),
    email: new FormControl('', { validators: [Validators.email] }),
    address: new FormControl(''),
    description: new FormControl(''),
  });

  boundData: CustomDialogData | undefined;
  uploadedFiles: File[] = [];
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;

  constructor(private service: BankService, private dialogService: DialogService, private toastService: ToastService,
              private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) {
      this.formGroup.patchValue(this.boundData.data);
    }
    if (this.boundData?.isView) this.formGroup.disable();
  }

  onUpload(event: FileSelectEvent) {
    this.uploadedFiles = [];
    for(let file of event.currentFiles) {
      this.uploadedFiles.push(file);
    }
  }

  updateLogo(event: FileSelectEvent) {
    const file = event.currentFiles[0];
    this.service.updateLogo(this.boundData?.data.id, file).subscribe({
      next: (value) => {
        if (this.boundData) this.boundData.data = value;
      },
      error: (err) => this.toastService.showToast('Mis à jour du logo', err.error, 'error')
    })
  }

  create() {
    this.isLoading = true;
    const bankDto = this.formGroup.value as IBank;
    const summary = 'Création banque'
    this.service.createBank(bankDto, this.uploadedFiles[0]).subscribe({
      next: (bank) => {
        this.toastService.showToast(summary, `La banque ${bank.name} a été créée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast(summary, error.error, 'error');
        this.isLoading = false;
      }
    });
  }

  update() {
    this.isLoading = true;
    const bankDto = this.formGroup.value as IBank;
    const summary = 'Mise à jour banque'
    this.service.updateBank(this.boundData!.data.id, bankDto).subscribe({
      next: (bank) => {
        this.toastService.showToast(summary, `La banque ${bank.name} a été mise à jour avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast(summary, error, 'error');
        this.isLoading = false;
      }
    });
  }
}
