import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAccount } from '../../core/models/account.model';
import { DynamicTableComponent } from '../../shared/components/dynamic-table/dynamic-table.component';
import { getDefaultPageable, Pageable, PageParams } from '../../core/models/page.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountService } from '../../core/services/account.service';
import { ToastService } from '../../core/services/toast.service';
import { customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../core/utils/utils';
import { CustomDialogData } from '../../core/models/custom-dialog.model';
import { take } from 'rxjs';
import { PaginatorState } from 'primeng/paginator';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { CustomAction, CustomTableHeader } from '../../core/models/custom-table.model';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  accounts: IAccount[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'bank.swiftCode',
      column: 'BIC',
      type: 'text'
    },
    {
      key: 'bank.name',
      column: 'Banque',
      type: 'text'
    },
    {
      key: 'type',
      column: 'Type',
      type: 'text'
    },
    {
      key: 'libelle',
      column: 'Libellé',
      type: 'text'
    },
    {
      key: 'number',
      column: 'Numéro',
      type: 'text'
    },
    {
      key: 'lastBalance.balance',
      column: 'Solde',
      type: 'number'
    },
    {
      key: 'currency.code',
      column: 'Devise',
      type: 'text'
    },

  ]
  pageable: PageParams = { page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: [] };
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];
  toolbarActions: CustomAction[] = [];

  constructor(
    private readonly accountService: AccountService,
    private readonly toastService: ToastService,
    public dialogService: DialogService
  ) {}

  ngOnInit() {
    this.toolbarActions = [
      {
        role: 'CREATE_ACCOUNT',
        icon: 'pi pi-plus',
        label: 'Nouveau',
        severity: 'primary',
        emit: () => this.createAccount(),
        visible: true
      }
    ];
    this.loadAccounts();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_ACCOUNT',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewOrEditAccount(item),
        visible: true
      },
      {
        role: 'UPDATE_ACCOUNT',
        icon: 'pi pi-pen-to-square',
        label: 'Modifier',
        severity: 'primary',
        emit: (item) => this.viewOrEditAccount(item, true),
        visible: true
      },
      {
        role: 'DELETE_ACCOUNT',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteAccount(item),
        visible: true
      }
    ];
  }

  loadAccounts(pageable: Pageable = getDefaultPageable()) {
    this.accountService.getAllAccounts(pageable).subscribe({
      next: page => {
        this.accounts = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des comptes', err.error, 'error');
      }
    })
  }

  createAccount() {
    const data = { isCreate: true } as CustomDialogData;
    this.ref = this.dialogService.open(AccountDetailsComponent, customDynamicDialog('Créer un compte', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadAccounts());
  }

  viewOrEditAccount(account: IAccount, isEdit: boolean = false) {
    const data = { isView: !isEdit, isEdit, data: account } as CustomDialogData;
    this.ref = this.dialogService.open(AccountDetailsComponent, customDynamicDialog('Détails du compte', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadAccounts());
  }

  deleteAccount(account: IAccount) {
    if (!account.id) {
      this.toastService.showToast('Erreur lors de la suppression du compte', 'Impossible de supprimer le compte', 'error');
      return;
    }
    this.accountService.deleteAccount(account.id).subscribe({
      next: () => {
        this.toastService.showToast('Compte supprimé', `Le compte ${account.libelle} a été supprimé avec succès.`);
        this.loadAccounts();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression du compte', err.error, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.loadAccounts(getDefaultPageable(event.page, event.rows));
}
