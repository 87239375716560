import {Component, OnInit} from '@angular/core';
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {AvatarModule} from "primeng/avatar";
import {InputTextModule} from "primeng/inputtext";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextareaModule} from "primeng/inputtextarea";
import {Ripple} from "primeng/ripple";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../core/services/toast.service";
import {InstitutionService} from "../../../core/services/institution.service";
import { getInstitutionTypeByValue, IInstitution, InstitutionType } from "../../../core/models/institution.model";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {ApiUrlPipe} from "../../../core/pipes/api-url.pipe";
import {countries} from "../../../../assets/countries";
import {IModule} from "../../../core/models/module.model";
import {ModuleService} from "../../../core/services/module.service";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {MultiSelectModule} from "primeng/multiselect";
import {AccordionModule} from "primeng/accordion";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {GroupPermissionsComponent} from "../../group/group-permissions/group-permissions.component";

@Component({
  selector: 'app-institution-details',
  standalone: true,
  imports: [
    // Modules
    FileUploadModule, AvatarModule, InputTextModule, ReactiveFormsModule, InputTextareaModule, MultiSelectModule,
    AccordionModule, DropdownModule,
    // Directives & Pipes
    NgForOf, NgIf, Ripple, ApiUrlPipe, UpperCasePipe,
    // Components
    DynamicTableComponent, GroupPermissionsComponent,
  ],
  templateUrl: './institution-details.component.html',
  styleUrl: './institution-details.component.scss'
})
export class InstitutionDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    type: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    phone: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    address: new FormControl(''),
    country: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl(''),
    modules: new FormControl<IModule[]>([]),
  });
  boundData: CustomDialogData | undefined;
  uploadedFiles: File[] = [];
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  types: string[] = Object.values(InstitutionType);
  countries = countries;
  products: IModule[] = [];
  productsDisabled: boolean = true;
  sort: Sort = { direction: Direction.DESC, orders: [{ property: 'code', ignoreCase: false }] };

  constructor(private service: InstitutionService, private dialogService: DialogService, private productService: ModuleService,
              private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.productService.getAllModules(getUnpagedPageable(this.sort)).subscribe({
      next: (page) => this.products = page.content,
      error: () => {
        this.toastService.showToast('Récupération des modules', 'Une erreur est survenue lors de la récupération des modules. Veuillez réessayer plus tard.', 'error');
        this.ref.close();
      }
    });
    this.boundData = this.instance?.data;
    if (this.boundData?.isCreate) this.isCreate = true;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) {
      this.formGroup.patchValue(this.boundData.data);
      this.formGroup.patchValue({ modules: this.boundData.data.modules });
    }
    if (this.boundData?.isView) this.formGroup.disable();
  }

  onUpload(event: FileSelectEvent) {
    for(let file of event.currentFiles) {
      this.uploadedFiles = [];
      this.uploadedFiles.push(file);
    }
  }

  create() {
    this.isLoading = true;
    const institutionDto = this.formGroup.value as IInstitution;
    institutionDto.type = <string>getInstitutionTypeByValue(institutionDto.type);
    this.service.createInstitution(institutionDto, this.uploadedFiles[0]).subscribe({
      next: (institution) => {
        this.toastService.showToast('Institution créé',`L'organisation ${institution.name} a été créée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création de l\'organisation', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const institutionDto = this.formGroup.value as IInstitution;
    institutionDto.type = <string>getInstitutionTypeByValue(institutionDto.type);
    this.service.updateInstitution(this.boundData?.data.id, institutionDto).subscribe({
      next: (institution) => {
        this.toastService.showToast('Institution modifié',`L'organisation ${institution.name} a été modifiée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification de l\'organisation', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  toggleAccordion = ($event: DropdownChangeEvent) => this.productsDisabled = $event.value == InstitutionType.ADMINISTRATION;

  toggleInstitution() {
    const enabled = this.boundData?.data.state == 'ACTIVE';
    this.service.toggleInstitution(this.boundData?.data.id).subscribe({
      next: () => {
        this.toastService.showToast(enabled ? 'Institution désactivée' : 'Institution activée', `L'institution a été ${enabled ? 'désactivée' : 'activée'} avec succès.`);
        this.ref.close();
      },
      error: () => {
        this.toastService.showToast('Erreur lors de la modification de l\'institution', 'Une erreur est survenue lors de la modification de l\'institution. Veuillez réessayer plus tard.', 'error');
      }
    })
  }
}
