import {Component, OnInit} from '@angular/core';
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {AvatarModule} from "primeng/avatar";
import {InputTextModule} from "primeng/inputtext";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextareaModule} from "primeng/inputtextarea";
import {Ripple} from "primeng/ripple";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../core/services/toast.service";
import {InstitutionService} from "../../../core/services/institution.service";
import {getInstitutionTypeByValue, IInstitution, InstitutionType} from "../../../core/models/institution.model";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DropdownModule} from "primeng/dropdown";
import {countries} from "../../../../assets/countries";
import {IModule} from "../../../core/models/module.model";
import {ModuleService} from "../../../core/services/module.service";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {MultiSelectModule} from "primeng/multiselect";
import {AccordionModule} from "primeng/accordion";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {GroupPermissionsComponent} from "../../group/group-permissions/group-permissions.component";

@Component({
  selector: 'app-institution-details',
  standalone: true,
  imports: [
    // Modules
    FileUploadModule, AvatarModule, InputTextModule, ReactiveFormsModule, InputTextareaModule, MultiSelectModule,
    AccordionModule, DropdownModule,
    // Directives & Pipes
    NgForOf, NgIf, Ripple, UpperCasePipe,
    // Components
    DynamicTableComponent, GroupPermissionsComponent,
  ],
  templateUrl: './institution-details.component.html',
  styleUrl: './institution-details.component.scss'
})
export class InstitutionDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    type: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    phone: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    address: new FormControl(''),
    country: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl(''),
    modules: new FormControl<string[]>([]),
  });
  uploadedFiles: File[] = [];
  isLoading: boolean = false;
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  types: string[] = Object.values(InstitutionType);
  countries = countries;
  modules: IModule[] = [];
  productsDisabled: boolean = true;
  sort: Sort = { direction: Direction.DESC, orders: [{ property: 'code', ignoreCase: false }] };

  constructor(
    private service: InstitutionService,
    private moduleService: ModuleService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.loadModules();
    this.boundData = this.instance?.data;
    const institution = this.boundData?.data as IInstitution;
    if (this.boundData?.isCreate) this.isCreate = true;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) {
      this.formGroup.patchValue(this.boundData.data);
      this.formGroup.patchValue({ modules: institution.modules.map(m => m.id) });
    }
    if (this.boundData?.isView) this.formGroup.disable();
  }

  onLogoUpdate($event: FileSelectEvent) {
    this.onUpload($event);
    this.service.updateLogo(this.boundData?.data.id, this.uploadedFiles[0]).subscribe({
      next: (institution) => {
        if (this.boundData) this.boundData.data = institution;
        this.toastService.showToast('Modification de logo', 'Le logo de l\'institution a été modifié avec succès.');
        this.ngOnInit();
      },
      error: (error) => {
        this.toastService.showToast('Modification du logo', error.error, 'error');
        this.ngOnInit();
      }
    });
  }

  onUpload(event: FileSelectEvent) {
    for(let file of event.currentFiles) {
      this.uploadedFiles = [];
      this.uploadedFiles.push(file);
    }
  }

  create() {
    this.isLoading = true;
    const institutionDto = this.formGroup.value as unknown as IInstitution;
    institutionDto.type = <string>getInstitutionTypeByValue(institutionDto.type);
    institutionDto.modules = this.modules.filter(m => this.formGroup.value.modules?.includes(m.id));
    this.service.createInstitution(institutionDto, this.uploadedFiles[0]).subscribe({
      next: (institution) => {
        this.toastService.showToast('Institution créé',`L'organisation ${institution.name} a été créée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création de l\'organisation', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const institutionDto = this.formGroup.value as unknown as IInstitution;
    institutionDto.type = <string>getInstitutionTypeByValue(institutionDto.type);
    this.service.updateInstitution(this.boundData?.data.id, institutionDto).subscribe({
      next: (institution) => {
        this.toastService.showToast('Institution modifié',`L'organisation ${institution.name} a été modifiée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification de l\'organisation', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  toggleInstitution() {
    const enabled = this.boundData?.data.state == 'ACTIVE';
    this.service.toggleInstitution(this.boundData?.data.id).subscribe({
      next: () => {
        const header = enabled ? 'Institution désactivée' : 'Institution activée';
        const message = `L'institution a été ${enabled ? 'désactivée' : 'activée'} avec succès.`;
        this.toastService.showToast(header, message);
        this.ref.close();
      },
      error: (err) => this.toastService.showToast('Modification de l\'institution', err.error, 'error')
    })
  }

  private loadModules() {
    this.moduleService.getAllModules(getUnpagedPageable(this.sort)).subscribe({
      next: (page) => this.modules = page.content,
      error: (err) => {
        this.toastService.showToast('Modules', err.error, 'error');
        this.ref.close();
      }
    });
  }
}
