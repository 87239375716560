import {Confirmation} from "primeng/api";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {PageParams} from "../models/page.model";

export const DEFAULT_ROWS_PER_PAGE: number = 10;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [ 10,20,30,50 ];
export const DEFAULT_PAGE_PARAMS: PageParams = {
  page: 0,
  first: 0,
  rows: 0,
  totalRecords: 0,
  rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
};

export const accountTypes : string[] = [ 'Devise locale', 'Devise étrangère', 'NOSTRO']


export function customDynamicDialog(header: string, data: any = {}, options: DynamicDialogConfig = {} as DynamicDialogConfig) {
  return {
    dismissableMask: true,
    modal: true,
    position: 'top-right',
    height: '100vh',
    style: {'max-height': '100%', 'margin': 0, 'min-width': '25%'},
    ...options,
    data,
    header
  } as DynamicDialogConfig;
}

/**
 * Create a custom confirmation dialog
 *
 * @param header        Header of the dialog
 * @param message       Message of the dialog
 * @param options       Custom options of the dialog
 * @param acceptLabel   Label of the accept button
 * @param rejectLabel   Label of the reject button
 */
export function customConfirmationDialog(header: string, message: string, options: Confirmation = {} as Confirmation,
                                         acceptLabel: string = 'Oui', rejectLabel: string = 'Non') {
  return {
    icon: 'pi pi-info-circle',
    acceptButtonStyleClass:"p-button-danger p-button-text",
    rejectButtonStyleClass:"p-button-text p-button-text",
    acceptIcon:"none",
    rejectIcon:"none",
    closeOnEscape: true,
    closeOnOutsideClick: true,
    ...options,
    header,
    message,
    acceptLabel,
    rejectLabel,
  } as Confirmation;
}
