import { Routes } from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {MainComponent} from "./pages/main/main.component";
import {InstitutionComponent} from "./components/institution/institution.component";
import {UserComponent} from "./components/user/user.component";
import {GroupComponent} from "./components/group/group.component";
import {AuthGuard} from "./core/guards/auth.guard";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {UserInfoComponent} from "./components/profile/user-info/user-info.component";
import {UpdatePasswordComponent} from "./components/profile/update-password/update-password.component";
import {DeviseComponent} from "./components/devise/devise.component";
import {ValidationComponent} from "./components/validation/validation.component";
import {AccountComponent} from "./components/account/account.component";
import {LogComponent} from "./components/log/log.component";
import {BankComponent} from "./components/bank/bank.component";
import {OperationComponent} from "./components/operation/operation.component";

export const profileRoutes: Routes = [
  { path: 'info', component: UserInfoComponent },
  { path: 'security', component: UpdatePasswordComponent },
  { path: '', redirectTo: 'info', pathMatch: 'full' }
]

export const mainRoutes: Routes = [
  { path: 'log', component: LogComponent },
  { path: 'institution', component: InstitutionComponent },
  { path: 'user', component: UserComponent },
  { path: 'group', component: GroupComponent },
  { path: 'devise', component: DeviseComponent },
  { path: 'validation', component: ValidationComponent },
  { path: 'account', component: AccountComponent },
  { path: 'bank', component: BankComponent },
  { path: 'operation', component: OperationComponent },
  { path: 'profile', component: ProfileComponent, children: profileRoutes },
  { path: '', redirectTo: 'institution', pathMatch: 'full' }
];

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'main', component: MainComponent, children: mainRoutes, canActivate: [AuthGuard]},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
