import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomTableHeader } from '../../../core/models/custom-table.model';
import { NgForOf, NgIf, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import {PaginatorModule} from "primeng/paginator";

export interface UploadableItem {
  isValid: boolean;
  errors: string[];
}

@Component({
  selector: 'app-upload-table',
  standalone: true,
  imports: [
    NgForOf, NgIf, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault,
    FormsModule, ButtonModule, TableModule, TooltipModule,
    DatePipe, CurrencyPipe, DecimalPipe, PaginatorModule
  ],
  templateUrl: './upload-table.component.html',
  styleUrls: ['./upload-table.component.scss']
})
export class UploadTableComponent<T extends UploadableItem> {

  @Input() headers: CustomTableHeader[] = [];
  @Input() data: T[] = [];
  @Output() saveData = new EventEmitter<T[]>();

  // Pagination properties
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0;

  selectedItems: T[] = [];
  initialValidState: boolean[] = [];

  ngOnInit() {
    this.initializeValidState()
    this.preSelectValidItems();
  }

  initializeValidState() {
    this.initialValidState = this.data.map(item => item.isValid);
  }

  isCheckboxDisabled(index: number): boolean {
    return !this.initialValidState[index];
  }

  preSelectValidItems() {
    this.selectedItems = this.data.filter(item => item.isValid);
  }

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }


  updateItemValidity() {
    const selectedIndices = new Set(this.selectedItems.map(item => this.data.indexOf(item)));
    this.data.forEach((item, index) => {
      item.isValid = selectedIndices.has(index);
    });
  }

  onSave() {
    this.updateItemValidity(); // Ensure isValid is up-to-date before saving
    this.saveData.emit(this.selectedItems);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  onSelectionChange() {
    console.log('Selection changed:', this.selectedItems);
    this.updateItemValidity();
  }
}
