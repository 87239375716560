import {Component} from '@angular/core';
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {NgForOf, NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {IOperation, IOperationExcel} from "../../../core/models/operation.model";
import {CustomTableHeader} from "../../../core/models/custom-table.model";
import {PageParams} from "../../../core/models/page.model";
import {UploadTableComponent} from "../../../shared/components/upload-table/upload-table.component";
import {OperationService} from "../../../core/services/operation.service";
import {ToastService} from "../../../core/services/toast.service";

@Component({
  selector: 'app-upload-operation',
  standalone: true,
  imports: [FileUploadModule, NgIf, NgForOf, Ripple, DynamicTableComponent, UploadTableComponent],
  templateUrl: './upload-operation.component.html',
  styleUrl: './upload-operation.component.scss'
})
export class UploadOperationComponent {

  instance: DynamicDialogComponent | undefined;
  uploadedFile: File = {} as File;
  isLoading: boolean = false;
  operations: IOperationExcel[] = [];
  columns: CustomTableHeader[] = [
    { key: 'account.number', column: 'Numéro compte', type: 'text' },
    { key: 'libelle', column: 'Libelle', type: 'text' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'currency.code' },
    { key: 'operationDate', column: 'Date opération', type: 'date' },
    { key: 'valueDate', column: 'Date valeur', type: 'date' },
    { key: 'sens', column: 'Sens', type: 'text' },
    { key: 'devise.code', column: 'Devise', type: 'text' },
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: [10,20,50]};

  constructor(
    private readonly operationService: OperationService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  onUpload(event: FileSelectEvent) {
    this.uploadedFile = event.files[event.currentFiles.length - 1];
  }

  upload() {
    this.operationService.uploadOperations(this.uploadedFile).subscribe({
      next: (data) => this.operations = data,
      error: (error) => console.error('Error uploading operations: ', error),
    });
  }

  saveValidOperations(validOperations: IOperationExcel[]) {
    const operations = validOperations.map((op: IOperationExcel) => {
      return {
        id: undefined,
        libelle: op.libelle,
        sens: op.sens == 'CREDIT' ? 'Crédit' : 'Débit',
        amount: op.amount,
        operationDate: op.operationDate,
        valueDate: op.valueDate,
        state: '',
        currency: op.currency,
        account: op.account,
        deleted: false
      } as IOperation
    });
    const summary = 'Chargement d\'opérations';
    this.operationService.createOperations(operations).subscribe({
      next: () => {
        this.toastService.showToast(summary, 'Les opérations ont été chargées avec succès.');
        this.ref.close();
      },
      error: (err) => this.toastService.showToast(summary, err.error, 'error')
    })
  }
}
