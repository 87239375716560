import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {getDefaultPageable, getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IInstitution} from "../models/institution.model";
import {IModule} from "../models/module.model";

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  private baseUrl = 'core/v1/institutions';

  constructor(private http: HttpClient) {}

  setModules(institutionId: string, modules: IModule[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${institutionId}/modules`, modules).pipe(take(1));
  }

  createInstitution(institutionDto: IInstitution, file: File): Observable<IInstitution> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('dto', JSON.stringify(institutionDto));
    return this.http.post<IInstitution>(this.baseUrl, formData).pipe(take(1));
  }

  updateInstitution(id: string, institutionDto: IInstitution): Observable<IInstitution> {
    return this.http.put<IInstitution>(`${this.baseUrl}/${id}`, institutionDto).pipe(take(1));
  }

  updateLogo(id: string, file: File): Observable<IInstitution> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.put<IInstitution>(`${this.baseUrl}/${id}/logo`, formData).pipe(take(1));
  }

  deleteInstitution(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  toggleInstitution(id: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/toggle/${id}`, {}).pipe(take(1));
  }

  getCurrentUserInstitution(): Observable<IInstitution> {
    return this.http.get<IInstitution>(`${this.baseUrl}/my`).pipe(take(1));
  }

  getInstitutionById(id: string): Observable<IInstitution> {
    return this.http.get<IInstitution>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getInstitutionByName(name: string): Observable<IInstitution> {
    return this.http.get<IInstitution>(`${this.baseUrl}/name/${name}`).pipe(take(1));
  }

  getAllInstitutions(pageable: Pageable): Observable<Page<IInstitution>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IInstitution>>(this.baseUrl, { params }).pipe(take(1));
  }

  getInstitutionByType(types: string[], pageable: Pageable): Observable<Page<IInstitution>> {
    let params =  getPageableAsParams(pageable)
      .append('types', types.join(','));

    return this.http.get<Page<IInstitution>>(`${this.baseUrl}/types`, { params }).pipe(take(1));
  }

  getInstitutionByDeleted(deleted: boolean = false, pageable: Pageable = getDefaultPageable()): Observable<Page<IInstitution>> {
    let params =  getPageableAsParams(pageable).append('deleted', deleted.toString());
    return this.http.get<Page<IInstitution>>(`${this.baseUrl}/del`, { params }).pipe(take(1));
  }

  getInstitutionByEnabled(enabled: boolean = true, pageable: Pageable = getDefaultPageable()): Observable<Page<IInstitution>> {
    let params =  getPageableAsParams(pageable).append('enabled', enabled.toString());
    return this.http.get<Page<IInstitution>>(`${this.baseUrl}/enabled`, { params }).pipe(take(1));
  }
}
