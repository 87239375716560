import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../core/services/toast.service";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {take} from "rxjs";
import {PaginatorState} from "primeng/paginator";
import {DeviseService} from "../../core/services/devise.service";
import {DeviseDetailsComponent} from "./devise-details/devise-details.component";
import {ICurrency} from "../../core/models/devise.model";

@Component({
  selector: 'app-devise',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './devise.component.html',
  styleUrl: './devise.component.scss'
})
export class DeviseComponent implements OnInit, OnDestroy {

  devises: ICurrency[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'description',
      column: 'Devise',
      type: 'text'
    },
    {
      key: 'code',
      column: 'Code',
      type: 'text'
    },
    {
      key: 'rate',
      column: 'Taux',
      type: 'text'
    },
    {
      key: 'symbol',
      column: 'Symbole',
      type: 'text'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(
    private readonly deviseService: DeviseService,
    private readonly toastService: ToastService,
    public readonly dialogService: DialogService
  ) {}

  ngOnInit() {
    this.loadDevises();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_DEVISE',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewOrEditDevise(item),
        visible: true
      },
      {
        role: 'UPDATE_DEVISE',
        icon: 'pi pi-pen-to-square',
        label: 'Modifier',
        severity: 'primary',
        emit: (item) => this.viewOrEditDevise(item, true),
        visible: true
      },
      {
        role: 'DELETE_DEVISE',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteDevise(item),
        visible: true
      }
    ];
  }

  loadDevises(pageable: Pageable = getDefaultPageable()) {
    this.deviseService.getAllDevises(pageable).subscribe({
      next: page => {
        this.devises = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des devises', err.error, 'error');
      }
    })
  }

  createDevise() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(DeviseDetailsComponent, customDynamicDialog('Création d\'devise', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadDevises());
  }

  viewOrEditDevise(devise: ICurrency, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: devise} as CustomDialogData;
    this.ref = this.dialogService.open(DeviseDetailsComponent, customDynamicDialog('Détails d\'devise', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadDevises());
  }

  deleteDevise(devise: ICurrency) {
    this.deviseService.deleteDevise(devise.code).subscribe({
      next: () => {
        this.toastService.showToast('Devise supprimée', `La devise ${devise.code} a été supprimée avec succès.`);
        this.loadDevises();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression de l\'devise', err.error, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => {
    console.log('Event: ', event);
    this.loadDevises(getDefaultPageable(event.page, event.rows));
  }

}
