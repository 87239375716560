<div class="flex flex-column w-full h-full align-content-between">
  <div class="flex mb-4 gap-2 w-full">
    <toolbar class="w-full" title="Opérations" [actions]="toolbarActions" />
  </div>

  <div>
    <dynamic-table [headers]="columns" [data]="operations" [pageParams]="pageable"
                   [actions]="actions" (paginate)="onPageChange($event)"  />
  </div>
</div>
