<p-sidebar #sidebarRef [visible]="true" [modal]="false">
  <ng-template pTemplate="headless">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
          <span class="inline-flex align-items-center gap-2">
              <span class="font-semibold text-4xl">alima <span class="text-sm text-color-secondary">by finappli</span></span>
          </span>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0">
          <li>
            <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                 class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
              <span class="font-medium">PRINCIPAL</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li>
                <a routerLink="account" pRipple class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-address-book mr-2"></i>
                  <span class="font-medium">Comptes</span>
                </a>
              </li>
              <li>
                <a routerLink="operation" pRipple class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-clipboard mr-2"></i>
                  <span class="font-medium">Opérations</span>
                </a>
              </li>
              <li>
                <a routerLink="bank" pRipple class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-building-columns mr-2"></i>
                  <span class="font-medium">Banques</span>
                </a>
              </li>
              <li>
                <a routerLink="devise" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-euro mr-2"></i>
                  <span class="font-medium">Devises</span>
                </a>
              </li>
              <li>
                <a routerLink="balance" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-database mr-2"></i>
                  <span class="font-medium">Soldes</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="list-none p-3 m-0">
          <li>
            <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                 leaveActiveClass="slideup" class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
              <span class="font-medium">ADMINISTRATION</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li>
                <a *hasRole="'READ_INSTITUTION'" routerLink="institution" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-home mr-2"></i>
                  <span class="font-medium">Institutions</span>
                </a>
              </li>
              <li *hasRole="'READ_USER'">
                <a routerLink="user" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-users mr-2"></i>
                  <span class="font-medium">Utilisateurs</span>
                </a>
              </li>
              <li *hasRole="'READ_GROUP'">
                <a routerLink="group" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-link mr-2"></i>
                  <span class="font-medium">Groupes</span>
                </a>
              </li>
              <li>
                <a routerLink="log" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-info-circle mr-2"></i>
                  <span class="font-medium">Logs</span>
                </a>
              </li>
              <li>
                <a routerLink="profile" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-cog mr-2"></i>
                  <span class="font-medium">Paramètres</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
        <a (click)="logout()" pRipple class="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle" />
          <div class="flex flex-column">
            <span class="font-bold">{{ username.toUpperCase() }}</span>
            <span class="font-medium">{{ institution }}</span>
          </div>
          <i class="pi pi-sign-out ml-auto"></i>
        </a>
      </div>
    </div>
  </ng-template>
</p-sidebar>
