import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {BankService} from "../../core/services/bank.service";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {IBank} from "../../core/models/bank.model";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {ToastService} from "../../core/services/toast.service";
import {TableModule} from "primeng/table";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {BankDetailsComponent} from "./bank-details/bank-details.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {PaginatorState} from "primeng/paginator";
import {take} from "rxjs";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";

@Component({
  selector: 'app-bank',
  standalone: true,
  imports: [
    // Modules
    TableModule,
    // Components
    DynamicTableComponent, ToolbarComponent,
    // Directives & Pipes
    NgForOf, ButtonDirective
  ],
  templateUrl: './bank.component.html',
  styleUrl: './bank.component.scss'
})
export class BankComponent implements OnInit, OnDestroy {

  banks: IBank[] = [];
  columns: CustomTableHeader[] = [
    { key: 'name', column: 'Nom', type: 'text' },
    { key: 'swiftCode', column: 'BIC', type: 'text' },
    { key: 'codeBank', column: 'Code banque', type: 'text' }
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];
  toolbarActions: CustomAction[] = [];

  constructor(
    private readonly bankService: BankService,
    private readonly toastService: ToastService,
    public dialogService: DialogService
  ) {}

  ngOnInit() {
    this.toolbarActions = [
      {
        role: 'CREATE_BANK',
        icon: 'pi pi-plus',
        label: 'Créer',
        severity: 'primary',
        emit: () => this.createBank(),
        visible: true
      }
    ];
    this.actions = this.getActions();
    this.loadBanks();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_BANK',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewOrEditBank(item),
        visible: true
      },
      {
        role: 'UPDATE_BANK',
        icon: 'pi pi-pencil',
        label: 'Modifier',
        severity: 'primary',
        emit: (item) => this.viewOrEditBank(item, true),
        visible: true
      },
      {
        role: 'DELETE_BANK',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteBank(item),
        visible: true
      }
    ];
  }

  loadBanks(pageable: Pageable = getDefaultPageable()) {
    this.bankService.getAllBanks(pageable).subscribe({
      next: page => {
        this.banks = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        };
      },
      error: err => this.toastService.showToast('Récupération des banques', err.error, 'error')
    });
  }

  createBank() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(BankDetailsComponent, customDynamicDialog('Création banque', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadBanks());
  }

  viewOrEditBank(bank: IBank, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: bank} as CustomDialogData;
    this.ref = this.dialogService.open(BankDetailsComponent, customDynamicDialog('Détails banque', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadBanks());
  }

  deleteBank(bank: IBank) {
    const summary = 'Suppression banque';
    this.bankService.deleteBank(bank.id).subscribe({
      next: () => {
        this.toastService.showToast(summary, `La banque ${bank.name} a été supprimé avec succès.`);
        this.loadBanks();
      },
      error: err => {
        this.toastService.showToast(summary, err.error, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.loadBanks(getDefaultPageable(event.page, event.rows));
}
