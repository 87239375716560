<div class="flex flex-column h-full">
  <div class="flex flex-column gap-2">
    <div *ngFor="let module of modules" class="field-checkbox">
      <p-checkbox [(ngModel)]="selectedModules" [label]="module.description" name="group" [value]="module"></p-checkbox>
    </div>
  </div>
  <div class="flex align-items-end flex-grow-1 justify-content-end">
    <p-button (onClick)="setModules()" label="Enregistrer" icon="pi pi-check" iconPos="right"></p-button>
  </div>
</div>

