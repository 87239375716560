import {Component, OnInit} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {DeviseService} from "../../../core/services/devise.service";
import {ToastService} from "../../../core/services/toast.service";
import {AccountType, IAccount} from "../../../core/models/account.model";
import {AccountService} from "../../../core/services/account.service";
import {getUnpagedPageable} from "../../../core/models/page.model";
import {InstitutionService} from "../../../core/services/institution.service";
import {getInstitutionTypeByValue, IInstitution, InstitutionType} from "../../../core/models/institution.model";
import {IBank} from "../../../core/models/bank.model";
import {BankService} from "../../../core/services/bank.service";
import {ICurrency} from "../../../core/models/devise.model";

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, DropdownModule, InputTextModule, ReactiveFormsModule,
    // Pipes & Directives
    NgIf, Button, Ripple,
  ],
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    type: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    bank: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    number: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    libelle: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    currency: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    rib: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    ribKey: new FormControl(''),
    iban: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    codeAgency: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    institutionId: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
  });

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  devises: ICurrency[] = [];
  institutions: IInstitution[] = [];
  account: IAccount = {} as IAccount;
  banks: IBank[] = [];
  accountTypes : AccountType[] = Object.values(AccountType);

  constructor(
    private readonly accountService: AccountService,
    private readonly dialogService: DialogService,
    private readonly bankService: BankService,
    private readonly deviseService: DeviseService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly institutionService: InstitutionService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.getBanks();
    this.getDevises();
    this.getInstitutions();
    this.boundData = this.instance?.data;
    if (this.boundData?.isView || this.boundData?.isEdit) {
      this.account = this.boundData?.data as IAccount;
      this.formGroup.patchValue({
        ...this.boundData?.data,
        currency: this.account.currency.code,
        bank: this.account.bank.id,
      });
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }

  getBanks() {
    const summary = 'Récupération des banques';
    this.bankService.getAllBanks(getUnpagedPageable()).subscribe({
      next: (page) => this.banks = page.content,
      error: (err) => this.toastService.showToast(summary, err.error, 'error')
    });
  }

  getInstitutions() {
    this.institutionService.getInstitutionByType(
      [getInstitutionTypeByValue(InstitutionType.BANK)!],
      getUnpagedPageable()
    ).subscribe({
      next: (page) => this.institutions = page.content,
      error: (err) => {
        const summary = 'Récupération des institutions';
        this.toastService.showToast(summary, err.error, 'error');
      }
    });
  }

  getDevises() {
    this.deviseService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => this.devises = page.content,
      error: (err) => this.toastService.showToast('Récupération des devises', err.error, 'error')
    });
  }

  create() {
    this.isLoading = true;
    const accountDto = this.parseAccount();
    if (accountDto === undefined) return;
    this.accountService.createAccount(accountDto).subscribe({
      next: (account) => {
        this.toastService.showToast('Compte créé', `Le compte ${account.libelle} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création du compte', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const accountDto = this.parseAccount();
    if (accountDto === undefined) return;
    this.accountService.updateAccount(this.boundData?.data.id, accountDto).subscribe({
      next: (account) => {
        this.toastService.showToast('Compte modifié', `Le compte ${account.libelle} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification du compte', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  private parseAccount() {
    this.isLoading = true;
    const currency = this.devises.find(devise => devise.code === this.formGroup.value.currency);
    const bank = this.banks.find(bank => bank.id === this.formGroup.value.bank);
    if (currency === undefined) {
      this.toastService.showToast('Création de compte', 'La devise est obligatoire', 'error');
      this.isLoading = false;
      return;
    }
    if (bank === undefined) {
      this.toastService.showToast('Création de compte', 'La banque est obligatoire', 'error');
      this.isLoading = false;
      return;
    }
    const account = this.formGroup.value as unknown as IAccount;
    account.bank = bank;
    account.currency = currency;
    return account;
  }

}
