<div>
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="card flex justify-content-center w-full">
      <p-fileUpload *ngIf="isCreate" class="w-full" (onSelect)="onUpload($event)" [showUploadButton]="false"
                    [showCancelButton]="false" [multiple]="false" accept="image/*" [maxFileSize]="1000000">
        <ng-template pTemplate="toolbar">
          <div class="py-3">Ajouter le logo de l'institution</div>
        </ng-template>
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <p-avatar *ngIf="!isCreate" [image]="boundData?.data?.logoUrl | apiUrl"
                styleClass="org-avatar" [style]="{ 'width': '64px', 'height': '64px' }" shape="circle" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="type" class="font-medium">Type de l'institution</label>
      <p-dropdown id="type" aria-describedby="type-help" formControlName="type" [editable]="true"
                  [options]="types" placeholder="Type d'organisation" />
      <small *ngIf="isCreate" id="type-help">Renseigner ici le type d'institution créé.</small>
    </div>
    <div class="flex flex-column gap-2">
      <p-multiSelect [options]="products" formControlName="modules" optionLabel="description"
                     [disabled]="productsDisabled" placeholder="Sélectionner les modules activés"/>
    </div>
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Nom</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
      <small *ngIf="isCreate" id="username-help">Renseigner ici le nom de l'institution.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="phone" class="font-medium">Email</label>
      <input pInputText id="email" aria-describedby="email-help" formControlName="email" />
      <small *ngIf="isCreate" id="email-help">Renseigner ici l'adresse mail de l'institution.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="phone" class="font-medium">Téléphone</label>
      <input pInputText id="phone" aria-describedby="phone-help" formControlName="phone" />
      <small *ngIf="isCreate" id="phone-help">Renseigner ici le numéro de téléphone de l'institution.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="address" class="font-medium">Adresse</label>
      <input pInputText id="address" aria-describedby="address-help" formControlName="address" />
      <small *ngIf="isCreate" id="address-help">Renseigner ici l'adresse de l'institution.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="country" class="font-medium">Pays</label>
      <small id="country-help">Sélectionner le pays de l'institution.</small>
      <p-dropdown
        [disabled]="boundData?.isView || false"
        [options]="countries"
        formControlName="country"
        optionLabel="country"
        editable="true"
        id="country"
        optionValue="country"
        [showClear]="true"
        placeholder="Choisir un pays">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
              src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
              style="width: 18px" />
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="phone" class="font-medium">Description</label>
      <textarea rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>

    <div class="flex gap-2 justify-content-end">
      <p-button *ngIf="!isCreate" pRipple [severity]="boundData?.data.state == 'ACTIVE' ? 'danger' : 'success'"
                [label]="boundData?.data.state == 'ACTIVE' ? 'Désactiver' : 'Activer'"
                (onClick)="toggleInstitution()"></p-button>
      <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}"
                [label]=" isCreate  ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
                [loading]="isLoading" (onClick)="isCreate ? create() : update()"></p-button>
    </div>
  </form>
</div>
