<div class="flex flex-column h-full pb-4">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="institution" class="font-medium">Institution</label>
      <p-dropdown id="institution" aria-describedby="institution-help" formControlName="institutionId" optionValue="id"
                  [editable]="true" filter="true" filterBy="name" [options]="institutions"
                  optionLabel="name" placeholder="Sélectionner une institution"/>
      <small *ngIf="boundData?.isCreate" id="institution-help">Renseigner ici le nom de l'institution.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="type" class="font-medium">Type de compte</label>
      <p-dropdown id="type" aria-describedby="type-help" formControlName="type"
                  [editable]="true" filter="true" filterBy="name" [options]="accountTypes"
                  placeholder="Sélectionner le type de compte"/>
      <small *ngIf="boundData?.isCreate" id="type-help">Renseigner ici le type de compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="bank" class="font-medium">Banque</label>
      <p-dropdown id="bank" aria-describedby="institution-help" formControlName="bank"
                  [editable]="true" filter="true" filterBy="name" [options]="banks" optionValue="id"
                  optionLabel="name" placeholder="Sélectionner une banque"/>
      <small *ngIf="boundData?.isCreate" id="bank-help">Renseigner ici le nom de la banque.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="number" class="font-medium">Code d'agence</label>
      <input pInputText id="codeAgency" aria-describedby="codeAgency-help" formControlName="codeAgency" />
      <small *ngIf="boundData?.isCreate" id="codeAgency-help">Renseigner ici le code d'agence'.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="number" class="font-medium">Numéro de compte</label>
      <input pInputText id="number" aria-describedby="number-help" formControlName="number" />
      <small *ngIf="boundData?.isCreate" id="number-help">Renseigner ici le numéro de compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="libelle" class="font-medium">Libellé</label>
      <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="libelle" />
      <small *ngIf="boundData?.isCreate" id="libelle-help">Renseigner ici le libellé du compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="devise" class="font-medium">Devise</label>
      <p-dropdown id="devise" aria-describedby="devise-help" formControlName="currency" [editable]="true" filter="true"
                  filterBy="code,description" [options]="devises" optionLabel="description"
                  optionValue="code" placeholder="Sélectionner une devise" />
      <small *ngIf="boundData?.isCreate" id="devise-help">Sélectionner la devise du compte.</small>
    </div>
      <div class="flex flex-column gap-2">
        <label for="rib" class="font-medium">RIB</label>
        <input pInputText id="rib" aria-describedby="rib-help" formControlName="rib" />
        <small *ngIf="boundData?.isCreate" id="rib-help">Renseigner ici le RIB du compte.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label for="ribKey" class="font-medium">Clé RIB</label>
        <input pInputText id="ribKey" aria-describedby="ribKey-help" formControlName="ribKey" />
        <small *ngIf="boundData?.isCreate" id="ribKey-help">Renseigner ici la clé RIB.</small>
      </div>
      <div class="flex flex-column gap-2 mb-4">
        <label for="iban" class="font-medium">IBAN</label>
        <input pInputText id="iban" aria-describedby="iban-help" formControlName="iban" />
        <small *ngIf="boundData?.isCreate" id="iban-help">Renseigner ici l'IBAN.</small>
      </div>
  </form>
  <div class="flex mb-4 gap-2 justify-content-end">
    <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}" class="mb-4"
              [label]=" boundData?.isCreate  ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="boundData?.isCreate ? create() : update()"></p-button>
  </div>
</div>

