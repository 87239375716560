<form [formGroup]="formGroup" class="flex flex-column gap-4">
  <div class="flex flex-column gap-2 w-full">
    <label class="font-semibold" for="logo">Logo</label>
    <p-fileUpload id="logo" *ngIf="isCreate" class="w-full" (onSelect)="onUpload($event)" [showUploadButton]="false"
                  [showCancelButton]="false" [multiple]="false" accept="image/*" [maxFileSize]="1000000">
      <ng-template pTemplate="toolbar">
        <div class="py-3">Ajouter le logo de la banque</div>
      </ng-template>
    </p-fileUpload>
    <p-avatar *ngIf="!isCreate" [image]="('/operation/' + boundData?.data?.logoUrl) | apiUrl"
              styleClass="org-avatar" [style]="{ 'width': '64px', 'height': '64px', 'object-fit': 'contain' }" shape="circle" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="name">Nom</label>
    <input id="name" type="text" pInputText formControlName="name" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="swiftCode">SWIFT Code - BIC </label>
    <input id="swiftCode" type="text" pInputText formControlName="swiftCode" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="codeBank">Code banque</label>
    <input id="codeBank" type="text" pInputText formControlName="codeBank" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="codeGuichet">Téléphone</label>
    <input id="codeGuichet" type="text" pInputText formControlName="telephone" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="codeCompte">Email</label>
    <input id="codeCompte" type="email" pInputText formControlName="email" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="address">Adresse</label>
    <input id="address" type="text" pInputText formControlName="address" />
  </div>
  <div class="flex flex-column gap-2">
    <label class="font-semibold" for="desc">Description</label>
    <textarea pInputTextarea id="desc" type="text" rows="5" cols="30" formControlName="description"></textarea>
  </div>
</form>
<div class="flex justify-content-end mt-4">
  <p-button *ngIf="isCreate" (onClick)="create()" [disabled]="formGroup.invalid" label="Enregistrer" />
  <p-button *ngIf="boundData?.isEdit" (onClick)="update()" [disabled]="formGroup.invalid" label="Enregistrer" />
</div>
