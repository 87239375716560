import {Component, OnInit} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {IGroup} from "../../../core/models/group.model";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../../core/services/user.service";
import {GroupService} from "../../../core/services/group.service";
import {ToastService} from "../../../core/services/toast.service";
import {IUser} from "../../../core/models/user.model";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {InstitutionService} from "../../../core/services/institution.service";
import {IInstitution} from "../../../core/models/institution.model";

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, DropdownModule, InputTextModule, ReactiveFormsModule, Button, Ripple,
    // Pipes & Directives
    NgIf,
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    firstname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    lastname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.email]}),
    phoneNumber: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    group: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    institution: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    enabled: new FormControl(true, {nonNullable: true}),
  });

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  institutions: IInstitution[] = [];
  groups: IGroup[] = [];
  user: IUser = {} as IUser;
  groupSort: Sort = {
    direction: Direction.ASC,
    orders: [
      {
        property: 'name',
        ignoreCase: false,
      },
    ],
  }
  institution: IInstitution = {} as IInstitution;

  constructor(private userService: UserService, private dialogService: DialogService, private groupService: GroupService,
              private institutionService: InstitutionService, private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.loadInstitutions();
    this.boundData = this.instance?.data;
    this.user = this.boundData?.data as IUser;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) {
      this.formGroup.patchValue(this.boundData.data);
      this.formGroup.patchValue({ group: this.user.group.id, institution: this.user.group.institution.id });
      this.getGroupsForInstitution(this.user.group.institution.id);
    }
    if (this.boundData?.isView) this.formGroup.disable();
  }

  loadInstitutions() {
    this.institutionService.getInstitutionByEnabled(true, getUnpagedPageable()).subscribe({
      next: (page) => this.institutions = page.content,
      error: () => {
        this.toastService.showToast('Récupération des institutions', 'Une erreur est survenue lors de la récupération des institutions. Veuillez réessayer plus tard.', 'error');
      }
    });
  }

  getGroupsForInstitution(id: string) {
    this.groupService.getGroupsByInstitutionId(id, getUnpagedPageable(this.groupSort)).subscribe({
      next: (page) => this.groups = page.content,
      error: () => {
        this.toastService.showToast('Récupération des groupes', 'Une erreur est survenue lors de la récupération des groupes. Veuillez réessayer plus tard.', 'error');
      }
    });
  }

  create() {
    this.isLoading = true;
    const userDto = this.formGroup.value as unknown as IUser;
    userDto.group = this.groups.find(g => g.id == this.formGroup.value.group) as IGroup;
    this.userService.createUser(userDto).subscribe({
      next: (user) => {
        this.toastService.showToast('Utilisateur créé',`L'utilisateur ${user.firstname} ${user.lastname} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création de l\'utilisateur', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const userDto = this.formGroup.value as unknown as IUser;
    userDto.group = this.groups.find(g => g.id == this.formGroup.value.group) as IGroup;
    this.userService.updateUser(this.boundData?.data.id, userDto).subscribe({
      next: (user) => {
        this.toastService.showToast('Utilisateur modifié',`L'utilisateur ${user.firstname} ${user.lastname} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Modification de l\'utilisateur', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  toggleUser() {
    const enabled = this.boundData?.data.enabled;
    this.userService.toggleUser(this.boundData?.data.id).subscribe({
      next: () => {
        this.toastService.showToast(enabled ? 'Utilisateur désactivé' : 'Utilisateur activé', `L'utilisateur a été ${enabled ? 'désactivé' : 'activé'} avec succès.`);
        this.ref.close();
      },
      error: () => {
        this.toastService.showToast('Modification de l\'utilisateur', 'Une erreur est survenue lors de la modification de l\'utilisateur. Veuillez réessayer plus tard.', 'error');
      }
    })
  }

  loadGroups($event: DropdownChangeEvent) {
    this.getGroupsForInstitution($event.value.id);
  }
}
